<!-- 商品块 加入进货车 可选显示 -->
<template>
    <div class="liveSendBody">
        <div class="right_bottom" v-if="liveRoom.roomStatus!=4">
            <transition name="fade">
                <div class="inMsg" v-show="intoName"><span>{{intoName}}</span>进入了直播间</div>
            </transition>
            <div class="inputTopIcons df-jc-s-b">
                <div class=" df-ai-c">
                    <el-popover placement="top-start" width="400" trigger="click">
                        <div class="emojis">
                            <span class="emoji f16" v-for="v of emoji" @click="setEmoji(v.emoji)" v-bind:key="v.emoji">{{v.emoji}}</span>
                        </div>
                        <el-button type="text" class=" f16" size="mini" title="表情" slot="reference">{{emoji[0].emoji}}</el-button>
                    </el-popover>
                    <el-popover placement="top-start" width="400" trigger="click" v-if="listIcon && listIcon.length" ref="iconPopover">
                        <div class="emojis">
                            <el-avatar shape="square" class="emoji_icon" size="large" :key="v.id" v-for="v of listIcon" :src="$oucy.ossUrl+v.iconUrl" @click.native="sendIcon(v.iconUrl)"></el-avatar>
                        </div>
                        <el-button type="text" class="m-l-10 inputTopIcon f20" size="mini" :title="listIcon[0].iconName" icon="el-icon-star-off" slot="reference">
                            <!-- <el-avatar shape="square" :size="size" :src="$oucy.ossUrl+listIcon[0].iconUrl"></el-avatar> -->
                        </el-button>
                    </el-popover>
                    <upload type='image/jpeg;image/png' @uploadBack="uploadBack" v-if="0">
                        <el-button class="inputTopIcon m-l-10" icon="el-icon-picture-outline" title="图片/视频" type="text"></el-button>
                    </upload>
                </div>
                <div class="f12 c-2 reply" v-if="targetMessage&&targetMessage.ltUserAuthBasicFrom">回复: <span class="c-20">{{targetMessage.ltUserAuthBasicFrom.detailNickname}}</span><i class="el-icon-error" @click="delReply"></i></div>
            </div>
            <div class=" df-ai-c">
                <el-input size="mini" v-model.trim="input" id="editor" maxlength="200" placeholder="请输入您想咨询的内容" @focus="setSelection" @blur="setSelection" @change="setSelection" @keyup.enter.native="sendText"></el-input>
                <el-button size="mini" class="m-l-10" type="primary" @click="sendText">发送</el-button>
            </div>
            <!-- <el-button @click="scrollTop(false)">scrollTop false</el-button> -->
        </div>
        <div class="pushs text-center" v-if="(liveRoom.identity==1 || liveRoom.identity==2) && liveRoom.roomStatus!=4">
            <div class="push" @click="openAddSpuDialog">
                <img src="@/assets/images/live/spu.png" alt="">
                <div>推商品</div>
            </div>
            <div class="push" @click="openAddEnterpriseDialog">
                <img src="@/assets/images/live/shop.png" alt="">
                <div>推商店</div>
            </div>
            <div class="push" @click="openAddCardDialog">
                <img src="@/assets/images/live/card.png" alt="">
                <div>推名片</div>
            </div>
            <div class="push" v-if="0">
                <img src="@/assets/images/live/coupons.png" alt="">
                <div>推优惠券</div>
            </div>
            <div class="push" @click="dialogVisible=true">
                <img src="@/assets/images/live/notice.png" alt="">
                <div>发送公告</div>
            </div>
            <div class="push" @click="openDialogVisible_user" v-if="liveRoom.identity==1">
                <img src="@/assets/images/live/assistant.png" alt="">
                <div>助理管理</div>
            </div>
        </div>
        <el-dialog title="发送公告" :visible.sync="dialogVisible" width="30%">
            <el-input type="textarea" placeholder="请输入公告内容" v-model="textarea" maxlength="200" show-word-limit></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="sendNotice">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 管理员 -->
        <el-dialog title="管理员" :visible.sync="dialogVisible_user" width="30%">
            <div class="text-left">
                <div class="boxs">
                    <div class="df box" v-for="(v,i) of roomAssistant" :key="i">
                        <img :src="$oucy.ossUrl + v.detailAvatar" v-if="v.detailAvatar" class="detailAvatar">
                        <div class="text p-l-10">
                            <div class="f14 line-1">{{v.detailNickname}}</div>
                            <div class="f12">{{v.userPhoneNumber}}</div>
                        </div>
                        <i class="el-icon-error delbox" @click="delRoomAssistant(v,i)"></i>
                    </div>
                </div>
            </div>
            <el-button @click="openDialogFormVisible">去添加</el-button>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_user = false">取 消</el-button>
                <el-button @click="seveUser()">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 添加商品弹框 -->
        <addSpuDialog title="推商品" ref="addSpuDialog" @callBack="callBackAddSpu" />
        <addEnterpriseDialog title="推企业" ref="addEnterpriseDialog" @callBack="callBackAddEnterprise" />
        <addCardDialog title="推名片" ref="addCardDialog" @callBack="callBackaddCard" />
        <addr :mapVisible="addrSelectVisible" @close="addrSelectVisible=false" @choose="addrSelectHandle" />
        <selectUserDialog ref="selectUserDialog" @callBack="callBackUser" type='checkbox' />
        <!-- 新聊天消息语音提醒 -->
        <audio src="https://aliyunoss.lesoujia.com/lsj_mobile/music/newsinfo.mp3" ref="newsinfo"></audio>
        <!-- 新的好友申请语音提醒 -->
        <audio src="https://aliyunoss.lesoujia.com/lsj_mobile/music/NewFriends.wav" ref="NewFriends"></audio>
        <!-- 系统消息提醒 -->
        <audio src="https://aliyunoss.lesoujia.com/lsj_mobile/music/System.mp3" ref="System"></audio>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

// import { message, schemeOrder } from "@/service/index"
import { notice, liveroom, icon } from '@/service/index.js'
import emoji from "@/util/emoji.json"

export default {
    name: "liveSend",
    props: {
        liveRoom: {
            type: Object,
            default: function() {
                return {}
            }
        },
        targetMessage: {
            type: Object,
            default: function() {
                return {}
            }
        },
        intoName: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            pageWidth: oucy.pageWidth,
            id: null,

            MyMedia: [],
            levelList: [],
            noticelist: [], //公告列表
            input: '123456',
            textarea: '123456',
            tab: 1,
            tab2: 2,
            input: null,
            listIcon: null,
            emoji: emoji.data,
            key: null,
            url: null,
            LiveStream: null,
            PushStream: {},

            selectionCount: 0,
            addrSelectVisible: false,
            // 公告
            dialogVisible: false,
            textarea: '',
            dialogVisible_user: false,
            roomAssistant: [],
        }
    },
    mounted() {
        this.queryAllListIcon()
        // setInterval(()=>{
        //     this.input=new Date().format('yyyy-MM-dd hh:mm:ss')
        //     this.sendText()
        // },0)
    },
    methods: {


        // 发送文字
        sendText() {
            if (!this.input) {
                this.$message('不能发送空内容')
                return
            }
            if (this.hasSend) {
                return
            } else {
                this.hasSend = true
                setTimeout(() => {
                    this.hasSend = false
                }, 5000)
            }
            this.sendMessage({ messageTypeEnum: 0, messageContent: this.$oucy.escapeHtml(this.input) }, () => {
                this.input = null
                this.hasSend = false
            })
        },
        // 发送表情
        sendIcon(url) {
            this.sendMessage({ messageTypeEnum: 2, messageContent: url })
            this.$refs.iconPopover.doClose()
        },
        // 发送图片
        sendImage(url) {
            this.sendMessage({ messageTypeEnum: 1, messageContent: url })
        },
        // 发送视频
        sendVideo(url) {
            this.sendMessage({ messageTypeEnum: 4, messageContent: url })
        },
        // 发送公告
        sendNotice() {
            this.sendMessage({ messageTypeEnum: 11, messageContent: this.textarea }, () => {
                this.textarea = '';
                this.dialogVisible = false
            })
        },
        // 发送
        sendMessage(SendMessage, callBack) {
            // 消息类型 文本0,图片1,表情2,语音3,视频4,地图位置5,企业店铺6,商品7,企业名片8, 个人名片9, 图文消息10, 公告11  
            SendMessage.id = this.liveRoom.id
            SendMessage.targetMessageId=this.targetMessage && this.targetMessage.id?this.targetMessage.id:null
            SendMessage.atUserId=this.targetMessage && this.targetMessage.ltUserAuthBasicFrom?this.targetMessage.ltUserAuthBasicFrom.id:null
            liveroom.sendLiveRoomMessage(SendMessage).then(res => {
                callBack && callBack()
                this.delReply()
            })
        },
        // 发送消息是否自己
        sendMessageHasThis(v) {
            return this.loginUser.id == v.message_from_user.id
        },
        // 收到
        receive(res) {
            if (!res) return
            // 当前聊天的
            let noReadSize = 0
            // 发送消息是否自己
            let sendMessageHasThis = this.sendMessageHasThis(res)
            if (this.cacheUser && this.cacheUser.id == res.message_session_id) {
                this.list.push({
                    ...res,
                    message_from_user: res.message_from_user
                })
                let ele = document.getElementById("msgList");
                if (ele.scrollHeight > (ele.scrollTop + 400 + 50) && !sendMessageHasThis) {
                    this.newMessageCount ? ++this.newMessageCount : this.newMessageCount = 1
                } else {
                    setTimeout(() => {
                        this.scrollTop(true)
                    })
                    // 单条已读
                    this.markMessageRead(res.id)
                }
                // 如果是图片 插入当前大图列表 
                if (res.message_type == 1) {
                    this.imageList.push(this.$oucy.ossUrl + res.message_body)
                }
            } else {
                noReadSize = res.session_no_read_count
            }

            for (var i = 0; i < this.queryAllSessionByUserList.length; i++) {
                // 删除旧的会话
                if (this.queryAllSessionByUserList[i].id == res.message_session_id) {
                    this.queryAllSessionByUserList.splice(i, 1)
                }
            }

            this.queryAllSessionByUserList.unshift({
                ...res,
                id: res.message_session_id,
                noReadSize: noReadSize,
                // 对方名称
                sessionUser: sendMessageHasThis ? res.message_from_point : res.message_from_user,
                sessionNickName: !sendMessageHasThis ? res.sessionMeNickName : res.sessionNickName,
                sessionLastMsg: res.message_body
            })

        },
        SetSelectionCount(v) {
            if (this.tab == 1) {
                this.selectionCount = 0
            } else
            if (v == null || v == undefined) {
                ++this.selectionCount
            } else {
                this.selectionCount = v
            }
        },
        // 关闭新消息
        closeNewMessage() {
            this.newMessageCount = 0
            this.markSessionRead(this.cacheUser)
        },
        uploadBack(v) {
            if (this.$oucy.getType(v) == 'video') {
                this.sendVideo(v)
            } else {
                this.sendImage(v)
            }
            console.log(v)
        },
        // 打开添加商品弹框
        openAddSpuDialog() {
            this.$refs.addSpuDialog.openDialogFormVisible()
        },
        // 回调添加商品
        callBackAddSpu(list) {
            console.log(list)
            for (let v of list) {
                this.sendMessage({ messageTypeEnum: 7, messageContent: v.id, v: v })
            }
        },
        // 打开管理员框
        openDialogVisible_user() {
            let roomAssistant = []
            for (let v of this.liveRoom.roomAssistantJson) {
                roomAssistant.push(v)
            }
            this.roomAssistant = roomAssistant
            this.dialogVisible_user = true
            console.log(this.roomAssistant)
        },
        // 打开设置助理弹框
        openDialogFormVisible() {
            this.$refs.selectUserDialog.openDialogFormVisible(this.roomAssistant)
        },
        // 设置助理 回调
        callBackUser(list) {
            let roomAssistant = []
            for (let v of list) {
                if (v.ltUserDetailFull) {
                    roomAssistant.push({
                        detailAvatar: v.ltUserDetailFull.detailAvatar,
                        userId: v.id,
                        detailNickname: v.ltUserDetailFull.detailNickname,
                        userPhoneNumber: v.userPhoneNumber,
                    })
                } else {
                    roomAssistant.push(v)
                }
            }
            this.roomAssistant = roomAssistant
        },
        // 删除用户
        delRoomAssistant(v, i) {
            this.roomAssistant.splice(i, 1)
        },
        // 保存用户
        seveUser() {
            let UpdateRoomAssistant = {
                id: this.liveRoom.id,
                roomAssistant: JSON.stringify(this.roomAssistant)
            }
            // this.roomAssistant = roomAssistant
            liveroom.updateRoomAssistant(UpdateRoomAssistant).then(res => {
                this.$message('操作成功')
                this.liveRoom.roomAssistantJson = this.roomAssistant
                this.dialogVisible_user = !true
            })
        },
        // 打开添加店铺弹框
        openAddEnterpriseDialog() {
            this.$refs.addEnterpriseDialog.openDialogFormVisible2()
        },
        // 回调添加店铺
        callBackAddEnterprise(list) {
            for (let v of list) {
                this.sendMessage({ messageTypeEnum: 6, messageContent: v.id, v: v })
            }
        },
        // 打开添加名片弹框
        openAddCardDialog() {
            this.$refs.addCardDialog.openDialogFormVisible()
        },
        // 回调添加名片
        callBackaddCard({ list, type }) {
            console.log(list)
            // 0 企业 1 个人
            for (let v of list) {
                this.sendMessage({ messageTypeEnum: type, messageContent: v.id, v: v })
            }
        },
        openAddr() {
            this.addrSelectVisible = true;
        },
        addrSelectHandle(addr) {
            console.log('addr::', addr)
            const me = this;
            this.addrSelectVisible = false;
            // let thisAddr = addr.address+'('+addr.name+')';
            // if(!!me.addrSelectPoint){
            let data = JSON.stringify({
                positionName: addr.name,
                address: addr.address,
                longitude: addr.location.lng,
                latitude: addr.location.lat,
            })
            this.sendMessage({ messageTypeEnum: 5, messageContent: data })
            // }
        },
        // 获取消息类型
        getMessageTypeText(v) {
            if (!v) return
            if (v.message_type == 0 || v.messageTypeEnum == 0) {
                return v.sessionLastMsg || v.message_body || v.messageContent
            } else {
                return this.messageTypeEnum[v.message_type || v.messageTypeEnum]
            }
        },
        // 设置表情
        setEmoji(v) {
            let editor = document.querySelector('#editor');
            if (this.input) {
                let start = this.input.substring(0, this.selectionEnd)
                let end = this.input.substring(this.selectionEnd, this.input.length)
                // 插入表情
                this.input = start + v + end
                setTimeout(() => {
                    // 设置焦点
                    editor.setSelectionRange((start + v).length, (start + v).length)
                    editor.focus()
                }, 0)
            } else {
                this.input = v
                editor.focus()
            }
        },
        // 记录焦点
        setSelection() {
            let editor = document.querySelector('#editor');
            // 开始位置 结束位置
            // this.selectionStart = editor.selectionStart;
            this.selectionEnd = editor.selectionEnd;
        },
        // 顶部Tab
        setTab(v) {
            this.tab = v
            if (v == 0) {
                this.queryGroupFriends()
            } else {
                this.SetSelectionCount()
                this.queryAllSessionByUser()
            }
            this.TheNote = this.updateGroup = this.rihhtType = this.rightTitle = this.cacheUser = null
        },


        callBackObserver(v) {
            console.log(111111111111)
            if (v > 0) {
                this.loadMore()
            }
        },
        callBackObserverBottom(v) {
            if (v > 0) {
                if (this.newMessageCount > 0) {
                    this.newMessageCount = 0
                    this.markSessionRead(this.cacheUser)
                }
            }
            console.log(2, v > 0)
        },

        loadMore() {
            console.log('loadMore')
            if (!this.hasMore) return
            if (this.rihhtType == 'message') {
                this.getMessageContentHisByUser()
            } else
            if (this.rihhtType == 'session') {
                // this.queryMessage()
            } else
            if (this.rihhtType == 'newUser') {
                this.queryCheckFriends()
            }

        },
        stop() {},
        initMessage() {
            this.showMessage = true
            this.queryAllListIcon()
        },
        // 获取系统消息
        getMessageContentHisByUser() {
            if (!this.hasMore) return
            this.hasMore = false
            message.getMessageContentHisByUser({
                limit: this.limit,
                start: this.start,
            }).then(res => {
                if (res && res.content) {
                    let ele = document.getElementById("msgList");
                    let height = ele.scrollHeight
                    res.content.reverse()
                    this.list.unshift(...res.content)
                    // 等待页面
                    setTimeout(() => {
                        ele.scrollTop = ele.scrollHeight - height;
                    }, 0);

                    ++this.start
                    this.hasMore = res.totalElements > this.list.length
                } else {
                    // this.hasMore=false
                }
            })
        },
        /**
         * 滚动
         * @param  { Boolean } isTop 滚动类型 是否到顶
         */
        scrollTop(isTop = true) {
            let ele = document.getElementById("msgList");
            this.animate(ele.scrollTop, isTop ? ele.scrollHeight : 0, isTop, ele)
        },
        /**
         * 动画
         * @param  { Number } i    开始位置
         * @param  { Number } to   目标位置
         * @param  { Boolean } isTop 滚动类型 是否到顶
         * @param  { objcet } ele  滚动元素
         */
        animate(i, to, isTop, ele) {
            if ((isTop == true && i < to) || (isTop == false && i > to)) {
                i = isTop ? i + 50 : i - 50
                ele.scrollTop = i
                requestAnimationFrame(() => {
                    this.animate(i, to, isTop, ele)
                });
            }
        },



        // 获取表情列表
        queryAllListIcon() {
            icon.queryAllListIcon({}).then(res => {
                if (res) {
                    this.listIcon = res
                }
            })
        },



        // 打开选择用户
        // openDialogFormVisible() {
        //     this.addUser()
        // },
        // 选择用户回调
        callBack(v) {
            console.log(v)
            this.type = 'add'
            this.form.nikeName = null
            this.form.groupId = null
            this.checkFriendOpject = v
            this.checkFriendVisibl = true
            // this.addFriend(v)
            // this.enterApplyForm.userAuthId = v.id
            // this.selectObj = v
        },
        // 加用户回调聊天
        callBackcreateSession(v) {
            this.createSession({ targetUserId: v.id })
        },
        // 加用户回调聊天
        newUserSession(v) {
            this.createSession({ targetUserId: v.ltUserAuthBasics.id })
        },
        // 获取是否自己
        getHasThis(v) {
            if (v.message_from_user && this.loginUser && v.message_from_user.id == this.loginUser.id) {
                return true
            } else {
                return false
            }
        },
        // 删除回复
        delReply(){
            this.$emit('delReply',{})
        },


    },
}
</script>
<style scoped lang="less">
.emojis {
    max-height: 150px;
    overflow: auto;

    .emoji {
        display: inline-block;
        cursor: pointer;
        padding: 4px;
        margin: 1px;
        border-radius: 4px;

        &:hover {
            background: #f1f1f1;
        }
    }

    .emoji_icon {
        display: inline-block;
        margin: 5px;
        cursor: pointer;
    }

}

.media {
    max-width: 200px;
    max-height: 200px;
}

.inputTopIcons {
    margin-top: -5px;
    padding-bottom: 5px;
}

.inputTopIcon {
    font-size: 16px;
    padding: 4px;
    color: #3A4454;
}

.pushs {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 10px;

    .push {
        cursor: pointer;
        box-sizing: border-box;
        width: 80px;
        padding: 8px;
        background: #F9F9F9;
        border-radius: 4px;
        margin: 10px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        /*line-height: 16px;*/
        color: #555C68;
    }
}

.detailAvatar {
    width: 42px;
    height: 42px;
}

.boxs {
    display: flex;
    justify-content: space-between;
    margin: 10px -10px 0;
    flex-wrap: wrap;
}

.box {
    width: 39%;
    // flex: 1;
    margin: 10px;
    border: 1px #f1f1f1 solid;
    padding: 10px;
    position: relative;
}

.delbox {
    font-size: 18px;
    color: rgba(0, 0, 0, .5);
    padding: 4px;
    position: absolute;
    right: -10px;
    top: -10px;
}

.right_bottom {
    position: relative;
}

.inMsg {
    color: #aaa;
    position: absolute;
    bottom: 66px;
    width: 100%;
    font-size: 12px;
    background: #f1f1f1;
    padding: 5px;

    span {
        color: #2090FF;
        margin-right: 5px;
    }
}
.c-20{
        color: #2090FF;

}
.reply{
    position:relative;
    .el-icon-error{
        cursor: pointer;
        position:absolute;
        right: -10px;
    }
}
</style>