<!-- 信息中心弹框 -->
<template>
    <div class="messageBoxs">
        <template v-if="messageObj.content && messageObj.content.length">
            <div class="messageBox" v-for="(v,i) of messageObj.content" :key="i">
                <!-- <div class="message_createtime text-center" v-if="v.message_createtime && $oucy.getShowDate(messageObj.content,i,'message_createtime')"><span>{{$oucy.timestampFormat(v.message_createtime)}}</span></div> -->
                <div class="box">
                    <div class="df-jc-s-b">
                        <div class="df-ai-c">
                            <img v-if="v && v.detailAvatar" :src="$oucy.ossUrl+v.detailAvatar" class="head">
                            <el-avatar class="head" src="https://aliyunoss.lesoujia.com/lsj_mobile/image/Live/visitor.png" v-else></el-avatar>
                            <div class="name pointer" v-if="v" @click="clickUser(v)">{{v.detailNickname}}</div>
                            <span class="live_identity live_identity_1" v-if="v.identity==1">主播</span>
                            <span class="live_identity" v-if="v.identity==2">管理员</span>
                        </div>
                        <div class="rightMore" v-if="(liveRoom.identity==2 || liveRoom.identity==1)&&liveRoom.roomStatus!=4">
                            <!-- <span class="f12 c-2 pointer m-r-5" title="回复">回复</span> -->
                            <el-dropdown @command="handleCommand">
                                <span class="el-dropdown-link">
                                    <i class="c-2 el-icon-arrow-down el-icon-more deg90"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="{type:'banned',data:{ltUserAuthBasicFrom:v},index:i}" v-if="v.identity!=1 && (type=='all'||type=='banned')">禁言</el-dropdown-item>
                                    <el-dropdown-item :command="{type:'nuBanned',data:{ltUserAuthBasicFrom:v},index:i}" v-if="v.identity!=1 && (type=='all'||type=='nuBanned')">解禁</el-dropdown-item>
                                    <el-dropdown-item :command="{type:'out',data:{ltUserAuthBasicFrom:v},index:i}" v-if="v.identity!=1 && (type=='all'||type=='out')">踢出</el-dropdown-item>
                                    <el-dropdown-item :command="{type:'unOut',data:{ltUserAuthBasicFrom:v},index:i}" v-if="v.identity!=1 && (type=='unOut')">解除黑名单</el-dropdown-item>
                                    <!-- <el-dropdown-item :command="{type:'del',data:v,index:i}">删评</el-dropdown-item> -->
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <!-- <img src="@/assets/images/header_logo.png" class="corner"> -->
                    </div>
                </div>
            </div>
        </template>
        <div class="df-jc-c df-ai-c c-2 f14" style="height:100%" v-else>暂无数据</div>
        <!-- <hasMore @callback="hasMore" :data="messageObj" v-if="messageObj && messageObj.content && messageObj.content.length"></hasMore> -->
    </div>
</template>
<script>
import { message, friend, group, session, icon } from "@/service/index"
import { localSet, localGet, localDel } from "@/store/store";
import emoji from "@/util/emoji.json"
// console.log(emoji)
export default {
    name: "liveUserList",
    props: {
        liveRoom: {
            type: Object,
            default: function() {
                return {}
            }
        },
        messageObj: {
            type: Object,
            default: function() {
                return {}
            },
        },
        type: {
            type: String,
            default: 'all'
        }
    },
    data() {
        return {}
    },
    computed: {
        imageList: function() {
            let imageList = []
            if (this.messageObj && this.messageObj.content) {

                for (let v of this.messageObj.content) {
                    if (v.message_type == 1) {
                        imageList.push(this.$oucy.ossUrl + v.message_body)
                    }
                }
            }
            return imageList
        }
    },
    mounted() {
        // this.eventBus.$off(this.$oucy.msgKeys.IM_MESSAGE).$on(this.$oucy.msgKeys.IM_MESSAGE, (res) => {
        //     console.log('收到信息', res);
        // });
    },
    methods: {
        hasMore() {
            this.$emit('callback', { type: "hasMore", data: this.messageObj })
        },
        clickUser(v) {
            this.$emit('callback', { type: "user", data: v })
        },
        handleCommand(command) {
            // console.log(command)
            this.$emit('callback', command)
            // this.$message('click on item ' + command);
        }
    },
    beforeDestroy() {
        this.eventBus.$off(this.$oucy.msgKeys.IM_MESSAGE);

    },

}
</script>
<style scoped lang="less">
.head {
    width: 28px;
    height: 28px;
    border-radius: 28px;
}

.name {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 16px;
    color: #AAAFB7;
    margin-left: 9px;
}

.media {
    max-width: 200px;
    max-height: 200px;
}

.messageBoxs {
    /*display: flex;*/
    /*flex-direction: column-reverse;*/
    margin-left: -20px;
    margin-right: -15px;
}

.messageBox {

    /*padding-bottom: 15px;*/
    .box {
        padding: 15px 20px 15px 20px;

        .rightMore {
            display: none;
        }

        &:hover {
            background: #f1f1f1;

            .rightMore {

                display: inline-block;
            }
        }
    }

    .text {
        display: inline-block;
        max-width: 80%;
        margin-top: 8pxx;
        margin-left: 40px;
        padding: 5px;
        text-align: left;
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 1.5;
        color: #555C68;
        background: #F8F8F8;
        border-radius: 8px;
        position: relative;

        &.emoji_icon {
            width: 48px;
            // height: auto;
        }
    }

    .triangular {
        position: absolute;
        top: -15px;
        left: 10px;
        display: inline-block;
        width: 0;
        height: 0;
        border-bottom: 10px solid #F8F8F8;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
    }

    .message_createtime {
        text-align: cursor;
        margin: 20px 0;

        span {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 16px;
            color: #AAAFB7;
            background: #F8F8F8;
            opacity: 1;
            border-radius: 16px;
            padding: 4px 16px;
        }
    }
}

.live_identity {
    display: inline-block;
    font-size: 12px;
    padding: 0px 4px;
    background: #82C46F;
    border-radius: 2px;
    color: #fff;
    margin-left: 8px;

    &.live_identity_1 {
        background: #E96666;
    }
}
</style>