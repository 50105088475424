<template>
    <div class="text-left newBodyWidth">
        <div style="margin-left:auto;margin-right: auto;" v-if="hasShow">
<!--            <div class="pageCenter breadcrumbBar">-->
<!--                <el-breadcrumb separator="/">-->
<!--                    <el-breadcrumb-item v-for="(item,index) in levelList" :key="index" :to="item.path">{{item.meta.title}}</el-breadcrumb-item>-->
<!--                </el-breadcrumb>-->
<!--                <el-button icon="el-icon-notebook-2" type="text">帮助手册</el-button>-->
<!--            </div>-->
            <div class="liveBody">
                <div class="liveBodyLeft">
                    <div class="liveBodyLeftTop">
                        <!-- 直播标题 -->
                        <liveTitle :liveRoom="liveRoom" />
                        <!-- 直播公告 -->
                        <liveNotice :noticelist="noticelist" @callBack="showNotice" class="m-t-10" v-if="noticelist && noticelist.length" />
                        <!-- 直播播放 -->
                        <!-- <aliplayer ref="aliplayer"></aliplayer> -->
                        <!-- <el-button @click="aliplayer">aliplayer</el-button> -->
                        <livePlay ref="livePlay" :replyUrlList="replyUrlList" :messageObj="messageObjPush" class="m-t-10" :liveRoom="liveRoom" :roomPassword="roomPassword" @settingLiveAction="settingLiveAction" :hasManage="liveRoom.identity==1" :LiveStream="LiveStream" @callBackHasMore="getPushList" />
                    </div>
                    <div class="tabsBox">
                        <div class="df-jc-s-b">
                            <div class="df tabs">
                                <div class="tab" :class="tab==1?'selected':''" @click="tab=1" v-if="liveRoom.identity==1 && liveRoom.roomStatus!=4">推流信息</div>
                                <div class="tab" :class="tab==2?'selected':''" @click="tab=2" v-if="liveRoom.roomTool!=0">图文直播</div>
                                <div class="tab" :class="tab==3?'selected':''" @click="tab=3">直播介绍</div>
                            </div>
                            <div v-if="liveRoom.identity==1">
                                <!-- 直播操作 0暂停 1禁止 2开播 3结束 4恢复 -->
                                <el-button size="mini" type="primary" v-if="liveRoom.roomStatus==1" @click="settingLiveAction(3)">恢复直播</el-button>
                                <el-button size="mini" type="warning" v-if="liveRoom.roomStatus==3" @click="settingLiveAction(2)">暂停直播</el-button>
                                <el-button size="mini" type="danger" v-if="liveRoom.roomStatus==3 || liveRoom.roomStatus==1" @click="settingLiveAction(1)">停止直播</el-button>
                            </div>
                        </div>
                        <div class="m-t-20" v-show="tab==1">
                            <div class="df-ai-c">
                                <div class="tab1_left">推流地址</div>
                                <el-input style="flex: 1;" id="copydom" v-model="url" @blur="url=PushStream.url" size="mini" placeholder="请输入内容"></el-input>
                                <el-button class="m-l-10" type="text" @click="Mycopy('copydom')">复制</el-button>
                            </div>
                            <div class="df-ai-c">
                                <div class="tab1_left">推流密钥</div>
                                <el-input style="flex: 1;" placeholder="请输入密码" size="mini" v-model="key" @blur="key=PushStream.key" show-password></el-input>
                                <el-input style="flex: 1;" class="copydom1" id="copydom1" placeholder="请输入密码" size="mini" v-model="key"></el-input>
                                <el-button class="m-l-10" type="text" @click="Mycopy('copydom1')">复制</el-button>
                            </div>
                            <div class="df-ai-c">
                                <div class="tab1_left">推流状态</div>
                                <el-button class="" size="mini" type="info" v-if="PushStream.status==1">未推流</el-button>
                                <el-button class="" size="mini" type="primary" v-if="PushStream.status==2">推流中</el-button>
                            </div>
                        </div>
                        <div class="text-left m-t-20" v-show="tab==2">
                            <div v-if="(liveRoom.identity==1 || liveRoom.identity==2) && liveRoom.roomStatus!=4">
                                <el-input type="textarea" placeholder="请输入图文直播文字" rows="4" v-model="mediaText" maxlength="200" show-word-limit>
                                </el-input>
                                <div class="m-t-15 df">
                                    <MyMedia :srcList="urlList" v-if="urlList" :isShowDel="true" @clickDel="clickDel" />
                                    <upload @uploadBack="uploadBack" accept="image/jpeg,image/jpg,image/png,image/gif">
                                        <i class="el-icon-plus avatar-uploader-icon uploader">
                                            <!-- <div class="f12" style="line-height: 1;position: relative;top:-30px">图片/视频</div> -->
                                        </i>
                                    </upload>
                                </div>
                                <div class="m-t-10">
                                    <el-button class="" size="mini" type="primary" @click="sendMeadia">发布图文直播</el-button>
                                </div>
                            </div>
                            <div class="block m-t-20 p-t-30">
                                <template v-if="mediaObj.content &&mediaObj.content.length">
                                    <el-timeline v-if="mediaObj && mediaObj.content">
                                        <el-timeline-item placement="top" v-for="(v,i) of mediaObj.content" :key="v.id">
                                            <div class="timeline">
                                                <div class="df-jc-s-b">
                                                    <div class="df-ai-c">
                                                        <div>
                                                            <img v-if="v && v.ltUserAuthBasicFrom && v.ltUserAuthBasicFrom.detailAvatar" :src="$oucy.ossUrl+v.ltUserAuthBasicFrom.detailAvatar" class="head">
                                                        </div>
                                                        <div class="m-b-10 p-5">
                                                            <span class="name pointer" v-if="v.ltUserAuthBasicFrom">{{v.ltUserAuthBasicFrom.detailNickname}}</span>
                                                            <span class="live_identity live_identity_1" v-if="v.identity==1">主播</span>
                                                            <span class="live_identity" v-if="v.identity==2">管理员</span>
                                                            <div class="c-2 m-l-10">{{$oucy.timestampFormat(v.message_createtime)}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="m-r-10 m-t-10"><i class="el-icon-delete pointer" @click="delMedia(v,i)" v-if="(liveRoom.identity==1 || liveRoom.identity==2) && liveRoom.roomStatus!=4"></i></div>
                                                </div>
                                                <p v-if="v.body && v.body.text">{{v.body.text}}</p>
                                                <MyMediaBig v-if="v.body && v.body.urlList" :srcList="v.body.urlList" class="m-t-15" />
                                            </div>
                                        </el-timeline-item>
                                    </el-timeline>
                                    <hasMore @callback="media_hasMore" :data="mediaObj" v-if="mediaObj"></hasMore>
                                </template>
                                <div class="df-jc-c df-ai-c c-2 f14 text-center" style="height:50vh;width: 100%;" v-else>
                                    <div><img src="@/assets/images/live/search_not.png" alt="">
                                        <div class="m-t-80">暂无图文直播</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-left m-t-20" v-show="tab==3">
                            <div v-if="liveRoom.roomDescriber" v-html="liveRoom.roomDescriber" class="roomDescriber"></div>
                            <div class="df-jc-c df-ai-c c-2 f14 text-center" style="height:50vh;width: 100%;" v-else>
                                <div><img src="@/assets/images/live/search_not.png" alt="">
                                    <div class="m-t-80">暂无直播介绍内容</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="liveBodyRight">
                    <div class="df-ai-c tabs2">
                        <div class="tab2" :class="tab2==1?'selected':''" @click.stop="tab2=1">直播互动</div>
                        <el-divider direction="vertical"></el-divider>
                        <div class="tab2" :class="tab2==3?'selected':''" @click="tab2=3">观众列表</div>
                        <el-divider direction="vertical"></el-divider>
                        <div class="tab2" :class="tab2==2?'selected':''" @click="tab2=2,getLiveRoomShareInvitationList()">邀请榜</div>
                    </div>
                    <!-- 评论框 -->
                    <div v-show="tab2==1">
                        <!-- 评论 -->
                        <liveMessageList :liveRoom="liveRoom" :messageObj="messageObj" @callback="liveMessageListCallback" v-if="messageObj"></liveMessageList>
                        <!-- 输入框 -->
                        <liveSend :liveRoom="liveRoom" :intoName="intoName" :targetMessage="targetMessage" @delReply="delReply"></liveSend>
                    </div>
                    <!-- 在线框 -->
                    <div v-show="tab2==3" class="">
                        <!-- 在线 -->
                        <div class="df-jc-s-a m-t-10 m-b-10" v-if="(liveRoom.identity==1 || liveRoom.identity==2)">
                            <el-button size="mini" :type="tab3==1?'primary':''" round @click="tab3=1">在线观众</el-button>
                            <el-button size="mini" :type="tab3==2?'primary':''" round @click="tab3=2">黑名单</el-button>
                            <el-button size="mini" :type="tab3==3?'primary':''" round @click="tab3=3">已禁言</el-button>
                        </div>
                        <!-- 在线观众 -->
                        <liveUserList v-show="tab3==1" :liveRoom="liveRoom" :messageObj="messageObjHeat" @callback="liveMessageListCallback" v-if="messageObjHeat"></liveUserList>
                        <!-- 黑名单 -->
                        <liveUserList v-show="tab3==2" type="unOut" :liveRoom="liveRoom" :messageObj="liveRoomBlackList" @callback="liveMessageListCallback" v-if="liveRoomBlackList"></liveUserList>
                        <liveUserList v-show="tab3==3" type="nuBanned" :liveRoom="liveRoom" :messageObj="messageObjBan" @callback="liveMessageListCallback" v-if="messageObjBan"></liveUserList>
                    </div>
                    <div v-show="tab2==2">
                        <div class="HOT_S">
                            <div class="df-jc-s-b HOT_S-top">
                                <div>用户排名</div>
                                <div class="p-r-10">拉粉数</div>
                            </div>
                            <div class="HOT_S-lists text-center">
                                <template v-if="liveRoomShareInvitationList && liveRoomShareInvitationList.length">
                                    <div class="HOT_S-list" v-for="(v,i) of liveRoomShareInvitationList" :key="i">
                                        <div class="df-jc-s-b df-ai-c">
                                            <div class="df-ai-c">
                                                <img v-if="i==0" src="@/assets/images/live/No1.png" alt="">
                                                <img v-else-if="i==1" src="@/assets/images/live/No2.png" alt="">
                                                <img v-else-if="i==2" src="@/assets/images/live/No3.png" alt="">
                                                <span v-else class="count m-l-5">{{i+1}}</span>
                                                <img :src="$oucy.ossUrl+ v.detailAvatar" class="head">
                                                <div class="name b_line-1">{{v.detailNickname}}</div>
                                                <!-- <img src="@/assets/images/header_logo.png" class="corner"> -->
                                            </div>
                                            <div class="powder p-r-5">{{v.count}}</div>
                                        </div>
                                    </div>
                                </template>
                                <div class="df-jc-c df-ai-c c-2 f14" style="height:100%" v-else>暂无数据</div>
                            </div>
                        </div>
                    </div>
                    <!-- 热播推荐 只有C看  -->
                    <div class="recommends m-t-80" v-if="!hasManage">
                        <div class="" style="height: 20px; background:#fafafa;margin-left: -20px;margin-right: -20px;"></div>
                        <div class="recommendsTitle">热播推荐</div>
                        <div class="recommend df" v-for="(v,i) of hotLiveRoomList" :key="v.id" @click="$oucy.openNewPage('/live?id='+v.id)">
                            <img :src="$oucy.ossUrl + v.roomIcon" alt="" class="recommendImg">
                            <div>
                                <div class="line-2 roomTitle">{{v.roomTitle}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else style="height:60vh;">
        </div>
        <!-- 踢出 禁言 解禁 -->
        <el-dialog :title="dialogVisibleTitle" :visible.sync="dialogVisible" width="30%">
            <div class="text-center">
                <div v-if="userObj.detailAvatar">
                    <el-avatar size="large" :src="$oucy.ossUrl + userObj.detailAvatar"></el-avatar>
                </div>
                <div class="m-t-20">{{userObj.detailNickname}}</div>
                <div class="m-t-20 c-4" v-if="dialogVisibleType=='out'">确定踢出并拉黑该用户吗？</div>
                <div class="m-t-20 c-4" v-if="dialogVisibleType=='unOut'">确定解除该用户黑名单吗？</div>
                <div v-if="dialogVisibleType=='banned'">
                    <el-button v-for="(v,i) of buttons" :key="i" class="m-t-15" :type="second==v.value?'primary':''" @click="second=v.value">{{v.text}}</el-button>
                    <!-- <el-button >主要按钮</el-button> -->
                </div>
                <div v-if="dialogVisibleType=='user'" class="m-t-20 c-4">
                    <el-button size="mini" type="info" @click="private" plain>私信</el-button>
                    <el-button size="mini" type="info" plain>主页</el-button>
                    <el-button size="mini" type="info" plain @click="addFriend">添加好友</el-button>
                </div>
                <div class="m-t-20 c-4" v-if="dialogVisibleType=='banned'">确定禁言该用户吗？</div>
                <div class="m-t-20 c-4" v-if="dialogVisibleType=='nuBanned'">确定解禁该用户吗？</div>
                <div class="m-t-20 c-4" v-if="dialogVisibleType=='del'">确定删评该用户吗？</div>
            </div>
            <span slot="footer" class="dialog-footer" v-if="dialogVisibleType!='user'">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisibleSubmit">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 公告 -->
        <el-dialog title="公告" :visible.sync="dialogVisible_notice" width="30%">
            <div class="text-left">
                {{notice}}
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_notice = false">知道了</el-button>
            </span>
        </el-dialog>
        <el-dialog title="私密直播" :visible.sync="dialogFormVisible" width="400px">
            <el-form :model="form">
                <el-form-item label="" label-width="0">
                    <el-input v-model="form.roomPassword" autocomplete="off" placeholder="请输入房间密码"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelPassword">取 消</el-button>
                <el-button type="primary" @click="getVerifyLiveRoomPassword">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { notice, liveroom, icon, liveroomp, friend, liveheat } from '@/service/index.js'
import liveSend from "@/components/liveSend.vue"
import aliplayer from "@/components/aliplayer.vue"
import liveMessageList from "@/components/liveMessageList.vue"
import liveUserList from "@/components/liveUserList.vue"
import { localSet, localGet, localDel } from "@/store/store";
export default {
    components: {
        liveSend,
        aliplayer,
        liveMessageList,
        liveUserList,
    },
    name: "live",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            id: null,
            fromUserId: null,
            liveRoom: {
                roomWatchPermissionJson: [],
                roomAssistantJson: [],
            },
            MyMedia: [],
            levelList: [],
            noticelist: [], //公告列表
            input: '123456',
            textarea: '123456',
            tab: 2,
            tab2: 1,
            tab3: 1,
            input: null,
            listIcon: null,
            // emoji: emoji.data,
            key: null,
            url: null,
            LiveStream: null,
            PushStream: {},
            messagePush: {
                start: 0,
                limit: 10,
                messageTypeEnum: 12,
                hasMore: true
            },
            messageObjPush: {
                content: [],
                hasMore: true
            },
            messageHeat: {
                start: 0,
                limit: 1000,
            },
            messageObjHeat: {
                content: [],
                hasMore: true
            },
            messageBan: {
                start: 0,
                limit: 10,
            },
            messageObjBan: {
                content: [],
                hasMore: true
            },
            message: {
                start: 0,
                limit: 10,
                hasMore: true
            },
            messageObj: {
                content: [],
                hasMore: true
            },
            mediaText: '',
            urlList: [],
            media: {
                start: 0,
                limit: 10,
                messageTypeEnum: 10,
                hasMore: true
            },
            form: {
                id: null,
                roomPassword: null
            },
            mediaObj: {
                content: [],
                hasMore: true
            },
            hasManage: false,
            hasAnchor: false,
            dialogVisibleTitle: '',
            dialogVisibleType: '',
            dialogVisible: false,
            dialogVisible_notice: false, //公告
            notice: ``,
            second: false,
            userObj: {},
            buttons: [
                { text: '5分钟', value: 5 },
                { text: '30分钟', value: 30 },
                { text: '1小时', value: 60 },
                { text: '2小时', value: 120 },
                { text: '1天', value: 60 * 24 },
            ],
            second: 30,
            isBanned: true,
            dialogFormVisible: false,
            liveRoomShareInvitationList: [],
            liveRoomBlackList: {},
            hasShow: false,
            subscribeCount: 0,
            roomPassword: null,
            liveRoomHeat: null,
            intoName: null,
            intoNameTimeID: null,
            targetMessage: {}, //回复
            replyUrlList: [], //回放
            hotLiveRoomList: [], //热播推荐
        }
    },
    computed: {

    },
    watch: {
        $route: {
            handler(to, from) {
                this.getBreadcrumb()
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        /*        if (typeof Worker === "undefined") {
                            alert('当前浏览器不支持webworker')
                        } else {
                            let worker = new SharedWorker('worker.js')
                            window.worker = worker
                            var i = 0;
                            document.querySelector('button').onclick = function() {
                                window.worker.port.postMessage('发送信息给worker' + i++)
                            }
                        }*/


        this.id = this.$route.query.id
        this.fromUserId = this.$route.query.fromUserId
        this.messageHeat.liveRoomId = this.id
        this.messageBan.liveRoomId = this.id
        this.loginUser = localGet(this.$oucy.userInfoKey)
        let livePwd = localGet('livePwd')
        if (livePwd) {
            // console.log(livePwd)
            if (livePwd.id == this.id) {
                this.roomPassword = livePwd.roomPassword
                this.message.roomPassword = livePwd.roomPassword
                this.media.roomPassword = livePwd.roomPassword
            }
        }
        this.getLiveRoom()

        this.saveLiveRoomWatch()
        this.queryHotLiveRoomList()



        //监听登录事件
        this.eventBus /*.$off(this.$oucy.msgKeys.LOGIN_SUCCESS)*/ .$on(this.$oucy.msgKeys.LOGIN_SUCCESS, (res) => {
            console.log('登录成功', res);
            location.reload();
            // this.getManage()
        });
        //监听退出登录事件
        // this.eventBus.$off(this.$oucy.msgKeys.LOGOUT_SUCCESS).$on(this.$oucy.msgKeys.LOGOUT_SUCCESS, (res) => {
        //     // console.log('退出登录',res);
        //     this.unInitLive()
        // });

        //图文消息
        this.eventBus.$off(this.$oucy.msgKeys.TEXT_IMAGE).$on(this.$oucy.msgKeys.TEXT_IMAGE, (res) => {
            console.log('图文消息', res);
            if (!Array.isArray(this.mediaObj.content)) {
                this.mediaObj.content = []
            }
            res.ltUserAuthBasicFrom = res.message_from_user
            res.body = JSON.parse(res.message_body)
            this.mediaObj.content.unshift(res)
            console.log(this.mediaObj.content)
        });

        //直播间消息
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM).$on(this.$oucy.msgKeys.LIVE_ROOM, (res) => {
            if (res.message_type == 11) {
                console.log('消息公告', res);
                this.noticelist.push(res)
                if (!this.hasManage) {
                    this.showNotice(res)
                }
            } else {
                console.log('直播间消息', res);
                if (!Array.isArray(this.messageObj.content)) {
                    this.messageObj.content = []
                }
                res.ltUserAuthBasicFrom = res.message_from_user
                this.messageObj.content.unshift(res)
                console.log(this.messageObj.content)
                // 消息类型 文本0,图片1,表情2,语音3,视频4,地图位置5,企业店铺6,商品7,企业名片8, 个人名片9, 图文消息10, 公告11 
                if (res.message_type > 5 && res.message_type < 10) {
                    this.messageObjPush.content.unshift(res)
                }
            }


        });

        //踢人消息
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_KICKING).$on(this.$oucy.msgKeys.LIVE_ROOM_KICKING, (res) => {
            console.log('踢人消息', res);
            if (res && this.loginUser && this.loginUser.id == res.id) {
                this.$oucy.go('liveList', () => {
                    this.$alert('你已被主播请出直播间')
                })
            } else {
                let data = {
                    name: res.detailNickname,
                    message_type: -1,
                    message_body: '踢出直播间',
                }
                this.messageObj.content.unshift(data)
                for (var i = 0; i < this.liveRoomBlackList.content.length; i++) {
                    if (this.liveRoomBlackList.content[i].id == res.id) {
                        this.liveRoomBlackList.content.splice(i, 1)
                        break
                    }
                }
                this.liveRoomBlackList.content.push(res)
            }

        });

        //开始直播
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_BEGIN).$on(this.$oucy.msgKeys.LIVE_ROOM_BEGIN, (res) => {
            console.log('开始直播', res);
            // 0预告 1暂停 2禁止开播 3开播中 4已结束
            this.liveRoom.roomStatus = 3
            this.$refs.livePlay.getLiveStream()

        });

        //暂停直播
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_STOP).$on(this.$oucy.msgKeys.LIVE_ROOM_STOP, (res) => {
            console.log('暂停直播', res);
            this.liveRoom.roomStatus = 1
            this.$refs.livePlay.pause()
        });
        //恢复直播
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_RESTORE).$on(this.$oucy.msgKeys.LIVE_ROOM_RESTORE, (res) => {
            console.log('恢复直播', res);
            this.liveRoom.roomStatus = 3
            this.$refs.livePlay.getLiveStream()
        });
        //结束直播
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_END).$on(this.$oucy.msgKeys.LIVE_ROOM_END, (res) => {
            console.log('结束直播', res);
            this.liveRoom.roomStatus = 4
            this.$refs.livePlay.stop()
            // this.$refs.livePlay.getLiveStream()
        });
        //禁言消息
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_DISABLE_SEND_MSG).$on(this.$oucy.msgKeys.LIVE_ROOM_DISABLE_SEND_MSG, (res) => {
            console.log('禁言消息', res);
            let data = {
                name: res.userAuth.detailNickname,
                message_type: -1,
                message_body: '禁言' + res.second,
            }
            this.messageObj.content.unshift(data)

            for (var i = 0; i < this.messageObjBan.content.length; i++) {
                if (this.messageObjBan.content[i].id == res.userAuth.id) {
                    this.messageObjBan.content.splice(i, 1)
                    break
                }
            }
            this.messageObjBan.content.push(res.userAuth)
            // this.$refs.livePlay.getLiveStream()
        });
        //取消禁言消息
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_ENABLE_SEND_MSG).$on(this.$oucy.msgKeys.LIVE_ROOM_ENABLE_SEND_MSG, (res) => {
            console.log('取消禁言消息', res);
            let data = {
                name: res.userAuth.detailNickname,
                message_type: -1,
                message_body: '取消禁言',
            }
            this.messageObj.content.unshift(data)
            for (var i = 0; i < this.messageObjBan.content.length; i++) {
                if (this.messageObjBan.content[i].id == res.userAuth.id) {
                    this.messageObjBan.content.splice(i, 1)
                    break
                }
            }
            // this.$refs.livePlay.getLiveStream()
        });
        //删除聊天互动消息
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_DELETE_MESSAGE).$on(this.$oucy.msgKeys.LIVE_ROOM_DELETE_MESSAGE, (res) => {
            console.log('删除聊天互动消息', res);
            let has=false
            for (let i = 0; i < this.messageObj.content.length; i++) {
                if (this.messageObj.content[i].id == res) {
                    this.messageObj.content.splice(i, 1)
                    has=true
                    break
                }
            }
            if(!has){
                for (let i = 0; i < this.mediaObj.content.length; i++) {
                    if (this.mediaObj.content[i].id == res) {
                        this.mediaObj.content.splice(i, 1)
                        break
                    }
                }
            }
            // this.$refs.livePlay.getLiveStream()
        });

        //禁止直播消息
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_BAN).$on(this.$oucy.msgKeys.LIVE_ROOM_BAN, (res) => {
            console.log('禁止直播消息', res);
            this.liveRoom.roomStatus = 2
            this.$refs.livePlay.pause()

        });

        //禁止直播恢复消息
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_BAN_RESTORE).$on(this.$oucy.msgKeys.LIVE_ROOM_BAN_RESTORE, (res) => {
            console.log('禁止直播恢复消息', res);
            this.liveRoom.roomStatus = 3
            this.$refs.livePlay.getLiveStream()
            // this.$refs.livePlay.getLiveStream()
        });

        //离开直播间
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_LEAVE).$on(this.$oucy.msgKeys.LIVE_ROOM_LEAVE, (res) => {

            console.log('离开直播间', res);
            for (var i = 0; i < this.messageObjHeat.content.length; i++) {
                if (this.messageObjHeat.content[i].id == res.id) {
                    this.messageObjHeat.content.splice(i, 1)
                    break
                }
            }
            --this.liveRoom.liveRoomPBasic.ponline

        });

        //进入直播间
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_ONLINE).$on(this.$oucy.msgKeys.LIVE_ROOM_ONLINE, (res) => {
            console.log('进入直播间', res);
            for (var i = 0; i < this.messageObjHeat.content.length; i++) {
                if (this.messageObjHeat.content[i].id == res.id) {
                    this.messageObjHeat.content.splice(i, 1)
                    break
                }
            }
            this.messageObjHeat.content.push(res)
            this.intoName = res.detailNickname
            this.intoNameTimeID && clearTimeout(this.intoNameTimeID)
            this.intoNameTimeID = setTimeout(() => {
                this.intoName = null
            }, 5000)
            if (this.liveRoom.liveRoomPBasic) {
                ++this.liveRoom.liveRoomPBasic.ponline
            }
        });
        // 收到推流状态更新
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_NO_STREAM_MESSAGE).$on(this.$oucy.msgKeys.LIVE_ROOM_NO_STREAM_MESSAGE, (res) => {
            console.log('收到推流状态更新：', res);
            if(this.liveRoom && this.liveRoom.identity==1 && this.PushStream.status !=res.liveStatus){
                // this.$alert('推流状态发生改变')
            }
            this.PushStream.status=res.liveStatus

        });

        //直播点赞
        this.eventBus.$off(this.$oucy.msgKeys.LIVE_ROOM_GIVE_LIKES).$on(this.$oucy.msgKeys.LIVE_ROOM_GIVE_LIKES, (res) => {
            if(this.liveRoom && this.liveRoom.liveRoomPBasic && res){
                this.liveRoom.liveRoomPBasic.plike=res
            }
            console.log('直播点赞', res);
        });

        // this.subscribe()
        // if (this.$oucy.getToken()) {
        // setTimeout(() => {
        // this.initLive()
        // }, 1000)
        // }
        this.saveLiveRoomHeat()
        this.liveRoomHeat = setInterval(() => {
            this.saveLiveRoomHeat()
        }, 5000)
        window.$vue = this
    },
    methods: {
        aliplayer() {
            this.$refs.aliplayer.getLiveStream(this.id)
        },
        initLive() {
            // this.unsubscribe()
            this.subscribe()
            this.getManage()
        },
        unInitLive() {
            this.hasManage = false
            this.unsubscribe()
        },
        // 订阅
        subscribe() {
            console.log('订阅')
            // this.$oucy.mqttSubscriber(`liveTopic_lsj_live/001`)
            if (this.$oucy.mqtt && this.$oucy.mqtt.isConnected()) {
                this.$oucy.mqttSubscriber(`${this.$oucy.mqttArgs.liveTopic}/${this.id}`)
                this.subscribeCount = 0
            } else {
                // 刷新页面要等 MQTT init 
                setTimeout(() => {
                    console.log('再次订阅')

                        ++this.subscribeCount
                    this.subscribeCount < 5 && this.subscribe()
                }, 2000)
            }

        },
        // 取消订阅
        unsubscribe() {
            this.$oucy.mqtt.unsubscribe(`${this.$oucy.mqttArgs.liveTopic}/${this.id}`)
            // this.$oucy.mqttUnsubscriber(`${this.$oucy.mqttArgs.liveTopic}/${this.id}`)
        },
        // 获取管理
        getManage() {
            let token = localGet(this.$oucy.tokenKey)
            let userInfo = localGet(this.$oucy.userInfoKey)
            if (token && userInfo && this.liveRoom && this.liveRoom.ltUserAuthBasic && this.liveRoom.ltUserAuthBasic.id == userInfo.id) {
                // 是主播
                this.hasManage = true
                // 获取推流地址
                this.getPushStream()
                console.log('true')
            } else {
                console.log('false')
                this.hasManage = false
            }
        },

        getBreadcrumb() {
            //$route.matched是一个数组 包含当前路由所有嵌套路径的路由记录
            let matched = this.$route.matched.filter(item => item.name)
            const first = matched[0];
            if (first && first.name !== '首页') {
                matched = [{ path: '/', meta: { title: '首页' } }].concat(matched)
            }
            this.levelList = matched
        },
        // 查详情
        getLiveRoom() {
            // ,fromUserId:'435356a1028a438ca6beead9236ea185'
            liveroom.getLiveRoom({ id: this.id, fromUserId: this.fromUserId, roomPassword: this.roomPassword }).then(res => {
                this.hasShow = true
                // 有数据
                if (res) {
                    res.roomWatchPermissionJson = res.roomWatchPermission ? JSON.parse(res.roomWatchPermission) : []
                    res.roomAssistantJson = res.roomAssistant ? JSON.parse(res.roomAssistant) : []
                    res.pauseImg = res.liveRoomStopImage ? JSON.parse(res.liveRoomStopImage)[0] : null
                    this.liveRoom = res
                    if(this.liveRoom.roomTool==0){
                        this.tab=3
                    }
                    this.$oucy.setTitle(this.liveRoom.roomTitle)
                    setTimeout(() => {
                        if (res.roomStatus == 3) {
                            this.$refs.livePlay.getLiveStream(this.id)
                        }
                        if (res.roomStatus != 4) {
                            this.initLive()
                        } else if (this.liveRoom.roomVideoEnable) {
                            this.liveRoomRecord()
                        }
                    })
                    if (this.liveRoom.identity) {
                        this.queryBanUserList()
                        this.getLiveRoomBlackList()
                    }
                    this.getLiveRoomOnlineUserList()
                }
                this.getManage()
                this.getANotice()
                this.getMessage()
                this.getMedia()
                this.getLiveRoomShareInvitationList()
                this.getPushList()
            }, err => {
                // 直播管理系统
                // 400101 输密码
                // 400102 是黑名单
                // 400103 无权限
                if (err.statusCode == 400104) {
                    this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.LOCAL_LOGIN, null);
                } else
                if (err.statusCode == 400103) {
                    this.$oucy.go('/acc/identity', () => {
                        this.$alert('你无权限观看当前直播间')
                    })
                } else
                if (err.statusCode == 400102) {
                    this.$oucy.go('liveList', () => {
                        this.$alert('你已被主播请出直播间')
                    })
                } else
                if (err.statusCode == 400101) {
                    this.dialogFormVisible = true
                }
                console.log(err)
            })
        },
        Mycopy(id) {
            let dom = document.getElementById(id)
            dom.select();
            document.execCommand("Copy");
            this.$message({
                type: 'success',
                message: '复制成功!'
            });
        },

        // 获取推流地址
        getPushStream() {
            liveroom.getPushStream({ id: this.id, roomPassword: this.roomPassword }).then(res => {
                // 有数据
                if (res) {
                    this.PushStream = res
                    this.key = res.key
                    this.url = res.url
                }

            })
        },
        // 搜索直播间消息
        getMessage() {
            this.queryLiveRoomMessage(this.message, (res) => {
                // 有数据
                if (res && res.content) {
                    // res.content.reverse()
                    if (this.messageObj == null || 'content' in this.messageObj == false) {
                        this.messageObj = { content: [] }
                    }
                    this.messageObj.content.push(...res.content)
                }
                this.messageObj.hasMore = res.last
                    ++this.message.start
            })
        },
        // 查找 推送列表
        getPushList() {
            this.queryLiveRoomMessage(this.messagePush, (res) => {
                // 有数据
                if (res && res.content) {
                    // res.content.reverse()
                    if (this.messageObjPush == null || 'content' in this.messageObjPush == false) {
                        this.messageObjPush = { content: [] }
                    }
                    this.messageObjPush.content.push(...res.content)
                }
                this.messageObjPush.hasMore = res.last
                    ++this.messagePush.start
            })
        },
        liveMessageListCallback(v) {
            console.log('liveMessageListCallback')
            if (v.type == 'hasMore') {
                this.getMessage()
                return
            } else
            if (v.type == 'del') {
                // this.dialogVisibleTitle='删除用户'
                this.$oucy.confirm('确定删除评论', '提示').then(res => {
                    this.delLiveMessage(v.data, v.index)
                }, err => {})
            } else
            if (v.type == 'out') {
                this.dialogVisibleTitle = '拉黑用户'
                this.dialogVisible = true
            } else
            if (v.type == 'unOut') {
                this.dialogVisibleTitle = '解除黑名单'
                this.dialogVisible = true
            } else
            if (v.type == 'banned') {
                this.dialogVisibleTitle = '禁言用户'
                this.dialogVisible = true
                this.isBanned = true
            } else
            if (v.type == 'nuBanned') {
                this.dialogVisibleTitle = '解禁用户'
                this.dialogVisible = true
                this.isBanned = false
            } else
            if (v.type == 'user') {
                this.dialogVisibleTitle = null
                this.dialogVisible = true
            } else
            if (v.type == 'reply') {
                this.dialogVisibleTitle = null
                this.targetMessage = v.data
                console.log('回复', this.targetMessage)
            }
            // console.log(v)
            this.userObj = v.data.ltUserAuthBasicFrom
            this.dialogVisibleType = v.type
            // this.$message(v.type)
        },
        delMedia(v,i){
            this.$oucy.confirm('确定删除图文直播', '提示').then(res => {
                this.delLiveMessage(v, i)
            }, err => {})
        },
        // 弹框提交
        dialogVisibleSubmit() {
            if (this.dialogVisibleType == 'out') {
                // 踢出用户
                this.kickingLiveUser(this.userObj)
            } else
            if (this.dialogVisibleType == 'unOut') {
                // 解除名单用户
                this.delLiveRoomBlack(this.userObj)
            } else
            if (this.dialogVisibleType == 'banned' || this.dialogVisibleType == 'nuBanned') {
                this.bannedLiveMessage(this.userObj)
            }
        },
        // 私信
        private() {
            this.dialogVisible = false
            this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, { targetUserId: this.userObj.id });
        },
        // 添加好友
        addFriend() {
            friend.addFriend({ targetUserId: this.userObj.id }).then(res => {
                // this.dialogVisible=false
                this.$message('操作成功')
            })
        },
        media_hasMore() {
            console.log('media_hasMore')
            this.getMedia()
        },
        //获取消息公告
        getANotice() {
            this.queryLiveRoomMessage({ messageTypeEnum: 11, start: 0, limit: 100, roomPassword: this.roomPassword }, (res) => {
                // 有数据
                if (res && res.content) {
                    res.content.reverse()
                    this.noticelist = res.content
                }
            })
        },
        showNotice(v) {
            this.dialogVisible_notice = true
            this.notice = v.message_body
        },
        // 获取直播间邀请榜
        getLiveRoomShareInvitationList() {
            liveroomp.getLiveRoomShareInvitationList({ liveRoomId: this.id, roomPassword: this.roomPassword }).then(res => {
                this.liveRoomShareInvitationList = res || []
            })
        },
        // 获取直播间黑名单
        getLiveRoomBlackList() {
            liveroom.getLiveRoomBlackList({ liveRoomId: this.id, start: 0, limit: 100, roomPassword: this.roomPassword }).then(res => {
                this.liveRoomBlackList = res || {}
            })
        },
        //获取图文消息
        getMedia() {
            this.queryLiveRoomMessage(this.media, (res) => {
                // 有数据
                if (res && res.content) {
                    // res.content.reverse()
                    for (let v of res.content) {
                        try {
                            v.body = JSON.parse(v.message_body)
                        } catch (err) {
                            // break
                        }
                    }
                    // res.content.reverse()
                    if (this.mediaObj == null || 'content' in this.mediaObj == false) {
                        this.mediaObj = { content: [] }
                    }
                    this.mediaObj.content.push(...res.content)
                    this.mediaObj.hasMore = res.last
                }
                ++this.media.start
            })

        },
        //删除直播聊天室消息
        delLiveMessage(v, i) {
            liveroom.delLiveMessage({ messageId: v.id, id: this.id }).then(res => {
                this.$message('操作成功')
            })

        },
        // 直播间禁言用户
        bannedLiveMessage(v) {
            liveroom.bannedLiveMessage({ targetUserId: v.id, id: this.id, second: this.second * 60, isBanned: this.isBanned }).then(res => {
                this.$message('操作成功')
                this.dialogVisible = false
            })
        },
        // 直播间踢人
        kickingLiveUser(v) {
            liveroom.kickingLiveUser({ targetUserId: v.id, id: this.id }).then(res => {
                this.$message('操作成功')
                this.dialogVisible = false
            })
        },
        // 删除黑名单
        delLiveRoomBlack(v) {
            liveroom.delLiveRoomBlack({ blackUserId: v.id, liveRoomId: this.id }).then(res => {
                this.$message('操作成功')
                for (var i = 0; i < this.liveRoomBlackList.content.length; i++) {
                    if (this.liveRoomBlackList.content[i].id == v.id) {
                        this.liveRoomBlackList.content.splice(i, 1)
                        break
                    }
                }
                this.dialogVisible = false
            })
        },
        // 消息类型 文本0,图片1,表情2,语音3,视频4,地图位置5,企业店铺6,商品7,企业名片8, 个人名片9, 图文消息10, 公告11  

        queryLiveRoomMessage(message = {}, callBack) {
            console.log(message)
            message.id = this.id
            liveroom.queryLiveRoomMessage(message).then(res => {
                callBack && callBack(res)
            })
        },
        uploadBack(v) {
            this.urlList.push(v)
        },
        clickDel(v) {
            this.urlList.splice(v.i, 1)
        },
        sendMeadia() {
            if (!this.mediaText && this.urlList.length == 0) {
                this.$message('发送内容不能为空')
                return
            }
            let SendMessage = {
                messageContent: JSON.stringify({
                    text: this.mediaText,
                    urlList: this.urlList,
                }),
                messageContentJson: {
                    text: this.mediaText,
                    urlList: this.urlList,
                },
                id: this.id,
                messageTypeEnum: 10,
            }
            // 消息类型 文本0,图片1,表情2,语音3,视频4,地图位置5,企业店铺6,商品7,企业名片8, 个人名片9, 图文消息10, 公告11  
            liveroom.sendLiveRoomMessage(SendMessage).then(res => {
                this.mediaText = ''
                this.urlList = []
            })
        },

        hasMore(v) {
            console.log(v)
        },
        // 直播操作 0暂停 1禁止 2开播 3结束 4恢复
        settingLiveAction(liveActionEnum) {
            let SettingLiveAction = {
                id: this.id,
                liveActionEnum: liveActionEnum
            }
            liveroom.settingLiveAction(SettingLiveAction).then(res => {
                this.$message('操作成功')

            })
        },
        getVerifyLiveRoomPassword() {
            if (!this.form.roomPassword) {
                this.$message('请输入密码')
                return
            }
            this.form.id = this.id
            liveroom.getVerifyLiveRoomPassword(this.form).then(res => {
                if (res) {
                    localSet('livePwd', this.form)
                    location.reload();
                } else {
                    this.$message('密码不正确')
                }

            })
        },
        cancelPassword(){
            this.dialogFormVisible = false
            this.$oucy.go('/liveList')
        },
        // 直播间心跳
        saveLiveRoomHeat() {
            let userId = this.loginUser && this.loginUser.id ? this.loginUser.id : this.$oucy.thisUUID
            liveheat.saveLiveRoomHeat({ liveRoomId: this.id, userId: userId }).then(res => {
                // setTimeout(()=>{
                //     this.saveLiveRoomHeat()
                // },5000)
            })
        },

        // 直播间增加观看人数
        saveLiveRoomWatch() {
            // let userId=this.loginUser && this.loginUser.id ?this.loginUser.id:this.$oucy.thisUUID
            liveroomp.saveLiveRoomWatch({ liveRoomId: this.id }).then(res => {
                // setTimeout(()=>{
                //     this.saveLiveRoomHeat()
                // },5000)
            })
        },
        // 在线列表
        getLiveRoomOnlineUserList() {

            liveheat.getLiveRoomOnlineUserList(this.messageHeat).then(res => {
                if (res && res.content) {
                    this.messageObjHeat.content.push(...res.content)
                    this.messageObjHeat.hasMore = res.last
                }
                this.liveRoom.liveRoomPBasic.ponline = res.numberOfElements
                    ++this.messageHeat.start
            })
        },
        // 禁言列表
        queryBanUserList() {

            liveroom.queryBanUserList(this.messageBan).then(res => {
                if (res) {
                    this.messageObjBan.content.push(...res)
                }
                this.messageObjBan.hasMore = true
                // ++this.messageBan.start
            })
        },
        // 直播间回放
        liveRoomRecord() {

            liveroom.liveRoomRecord({ liveRoomId: this.id }).then(res => {
                this.replyUrlList = res
                // ++this.messageBan.start
            })
        },
        // 热播推荐
        queryHotLiveRoomList() {
            liveroom.queryHotLiveRoomList({}).then(res => {
                this.hotLiveRoomList = res
                // ++this.messageBan.start
            })
        },
        // 删除回复
        delReply() {
            this.targetMessage = {}
        },
    },
    beforeDestroy() {
        this.liveRoomHeat && clearInterval(this.liveRoomHeat)
        this.unsubscribe()
    }
}
</script>
<style scoped lang="less">
.breadcrumbBar {
    padding: 10px auto;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.liveBody {
    display: flex;
    margin-top: 12px;
}

.liveBodyLeft {
    /*padding: 20px 30px;*/
    flex: 1;
}

.liveBodyLeftTop {
    background: #fff;
    padding: 30px;
}

.liveBodyRight {
    margin-left: 10px;
    width: 390px;
}

.tabsBox {
    background: #fff;
    margin-top: 20px;
    padding: 30px;
}

.tabs {
    background: #fff;
}

.tab {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    /*line-height: 16px;*/
    margin-right: 60px;
    color: #555C68;
    cursor: pointer;
    font-weight: 400;

    &.selected {
        color: #3A4454;
        font-weight: bold;
    }
}

.tab1_left {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 24px;
    color: #A1A6AE;
    text-align: left;
    width: 80px;
}

.avatar-uploader-icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
    border: 1px solid #f1f1f1;
}

.liveBodyRight {
    background: #fff;
    padding: 20px;
}

.tabs2 {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding-bottom: 20px;

    .tab2 {
        cursor: pointer;

        &.selected {
            color: #2090FF;
        }
    }
}

.head {
    width: 28px;
    height: 28px;
    border-radius: 28px;
}

.name {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 16px;
    color: #AAAFB7;
    margin-left: 9px;
}

.corner {
    height: 20px;
    margin-left: 5px;
}

.messageBoxs {
    height: 600px;
    overflow: auto;
}


.pushs {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 10px;

    .push {
        box-sizing: border-box;
        width: 80px;
        padding: 8px;
        background: #F9F9F9;
        border-radius: 4px;
        margin: 10px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        /*line-height: 16px;*/
        color: #555C68;
    }
}

.HOT_S-top {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 16px;
    color: #AAAFB7;
}

.HOT_S-lists {
    height: 600px;
    overflow: auto;
}

.HOT_S-list {
    margin: 20px 0;

    .count {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 800;
        /*line-height: 0px;*/
        color: #FFF6EB;
        width: 18px;
        height: 18px;
        background: #CDD6DE;
        border-radius: 50%;
        margin-right: 10px;
    }

    .powder {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 16px;
        color: #AAAFB7;
    }
}

.live_identity {
    display: inline-block;
    font-size: 12px;
    padding: 0px 4px;
    background: #82C46F;
    border-radius: 2px;
    color: #fff;
    margin-left: 8px;

    &.live_identity_1 {
        background: #E96666;
    }
}

.timeline {
    // margin-left: 100px;
    margin-left: -10px;
    margin-top: -30px;
    padding-left: 10px;

    &:hover {
        background: #f1f1f1;
    }
}

.recommendsTitle {
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    line-height: 16px;
    color: #555C68;
    margin-top: 23px;
}

.recommend {
    margin: 15px 0;
    cursor: pointer;

    .recommendImg {
        width: 158px;
        height: 88px;
        border-radius: 4px;
        padding-right: 10px;
    }

    .roomTitle {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #555C68;
    }
}

.copydom1 {
    position: absolute;
    left: -1000px;
    top: -1000px;
    opacity: 0;
}

/deep/ .el-timeline-item__timestamp {
    color: #2090FF;
}

.roomDescriber {
    /deep/ img {
        max-width: 100%;
    }
}

.head {
    width: 28px;
    height: 28px;
    border-radius: 28px;
}
</style>