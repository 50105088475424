<!-- 信息中心弹框 -->
<template>
    <div class="messageBoxs">
        <div class="messageBox" v-for="(v,i) of messageObj.content" :key="i">
            
            <div class="message_createtime text-center" v-if="v.message_type==-1"><span>用户<strong>{{v.name}}</strong>被管理员 <strong>{{v.message_body}}</strong></span></div>
            <div class="message_createtime text-center" v-if="v.message_createtime && $oucy.getShowDateReverse(messageObj.content,i,'message_createtime')"><span>{{$oucy.timestampFormat(v.message_createtime)}}</span></div>
            <div class="box" v-if="v.message_type!=-1">
                <div class="df-jc-s-b">
                    <div class="df-ai-c">
                        <img v-if="v.ltUserAuthBasicFrom && v.ltUserAuthBasicFrom.detailAvatar" :src="$oucy.ossUrl+v.ltUserAuthBasicFrom.detailAvatar" class="head">
                        <div class="name pointer" v-if="v.ltUserAuthBasicFrom" @click="clickUser(v)">{{v.ltUserAuthBasicFrom.detailNickname}}</div>
                        <span class="live_identity live_identity_1" v-if="v.identity==1">主播</span>
                        <span class="live_identity" v-if="v.identity==2">管理员</span>
                    </div>
                    <div class="rightMore" v-if="liveRoom.roomStatus!=4">
                        <span class="f12 c-2 pointer m-r-5" @click="handleCommand({type:'reply',data:v,index:i})" title="回复">回复</span>
                        <el-dropdown @command="handleCommand" v-if="(liveRoom.identity==1 || liveRoom.identity==2)">
                            <span class="el-dropdown-link">
                                <i class="c-2 el-icon-arrow-down el-icon-more deg90"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="{type:'banned',data:v,index:i}" v-if="v.identity!=1">禁言</el-dropdown-item>
                                <el-dropdown-item :command="{type:'nuBanned',data:v,index:i}" v-if="v.identity!=1">解禁</el-dropdown-item>
                                <el-dropdown-item :command="{type:'out',data:v,index:i}" v-if="v.identity!=1">踢出</el-dropdown-item>
                                <el-dropdown-item :command="{type:'del',data:v,index:i}">删评</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <!-- <img src="@/assets/images/header_logo.png" class="corner"> -->
                </div>
                <!-- 文字 -->
                <div class="text line-1" v-if="v.message_type==0"><span class="triangular"></span>
                    <span class="reply" v-if="v.atUserName">回复<strong>{{v.atUserName}}：</strong></span>
                    <span v-html="v.message_body"></span></div>
                <!-- 图片 -->
                <div class="text image" v-else-if="v.message_type==1"><span class="triangular"></span>
                    <span class="reply" v-if="v.atUserName">回复<strong>{{v.atUserName}}：</strong></span>
                    <el-image class="media" :src="$oucy.ossUrl+v.message_body" :preview-src-list="imageList"></el-image>
                </div>
                <!-- 表情 -->
                <div class="text emoji_icon" v-else-if="v.message_type==2"><span class="triangular"></span>
                    <span class="reply" v-if="v.atUserName">回复<strong>{{v.atUserName}}：</strong></span>
                    <el-image class="media" :src="$oucy.ossUrl+v.message_body"></el-image>
                </div>
                <!-- 视频 -->
                <div class="text video" v-else-if="v.message_type==4"><span class="triangular"></span>
                    <span class="reply" v-if="v.atUserName">回复<strong>{{v.atUserName}}：</strong></span>
                    <video class="media" controls="1" :src="$oucy.ossUrl+v.message_body"></video>
                </div>
                <!-- 位置 -->
                <div class="text messageMap" v-else-if="v.message_type==5">
                    <div style="width: 300px;">
                        <span class="reply" v-if="v.atUserName">回复<strong>{{v.atUserName}}：</strong></span>
                        <messageMap :dataStr="v.message_body" :id="'container_'+i" />
                        <!-- <enterprise :data="v.ltEnterpriseInfoBasic"></enterprise> -->
                    </div>
                    <span class="triangular"></span>
                </div>
                <div class="text enterprise" v-else-if="v.message_type==6"><span class="triangular"></span>
                    <div style="width: 200px;">
                        <span class="reply" v-if="v.atUserName">回复<strong>{{v.atUserName}}：</strong></span>
                        <enterprise :data="v.ltEnterpriseInfoBasic" @click.native="saveLiveRoomClick"></enterprise>
                    </div>
                </div>
                <div class="text spu" v-else-if="v.message_type==7"><span class="triangular"></span>
                    <div style="width: 200px;">
                        <span class="reply" v-if="v.atUserName">回复<strong>{{v.atUserName}}：</strong></span>
                        <spu :data="v.furnitureSpuFull" @click.native="saveLiveRoomClick"/>
                    </div>
                </div>
                <!-- 企业名片 -->
                <div class="text" v-else-if="v.message_type==8"><span class="triangular"></span>
                    <span class="reply" v-if="v.atUserName">回复<strong>{{v.atUserName}}：</strong></span>
                    <card :data="v.cardEnterpriseBasic" :hasEnterprise="true" @click.native="saveLiveRoomClick"></card>
                </div>
                <!-- 个人名片 -->
                <div class="text" v-else-if="v.message_type==9"><span class="triangular"></span>
                    <span class="reply" v-if="v.atUserName">回复<strong>{{v.atUserName}}：</strong></span>
                    <card :data="v.cardPersonBasic" @click.native="saveLiveRoomClick"></card>
                </div>
            </div>
        </div>
        <hasMore @callback="hasMore" :data="messageObj" v-if="messageObj && messageObj.content && messageObj.content.length"></hasMore>
        <div class="df-jc-c df-ai-c c-2 f14" style="height:100%" v-else>暂无互动数据</div>
    </div>
</template>
<script>
import { message, friend, group, session, icon,liveroomp } from "@/service/index"
import { localSet, localGet, localDel } from "@/store/store";
import emoji from "@/util/emoji.json"
// console.log(emoji)
export default {
    name: "liveMessageList",
    props: {
        messageObj: {
            type: Object,
            default: function() {
                return {}
            },
        },
        liveRoom: {
            type: Object,
            default: function() {
                return {}
            },
        }

    },
    data() {
        return {
            id:null,
        }
    },
    computed: {
        imageList: function() {
            let imageList = []
            if (this.messageObj && this.messageObj.content) {

                for (let v of this.messageObj.content) {
                    if (v.message_type == 1) {
                        imageList.push(this.$oucy.ossUrl + v.message_body)
                    }
                }
            }
            return imageList
        }
    },
    mounted() {
        this.id = this.$route.query.id
    },
    methods: {
        hasMore() {
            this.$emit('callback', { type: "hasMore", data: this.messageObj })
        },
        clickUser(v){
            this.$emit('callback', { type: "user", data: v })
        },
        handleCommand(command) {
            // console.log(command)
            this.$emit('callback', command)
            // this.$message('click on item ' + command);
        },
        // 直播间增加点击次数 通过推送点击进来的
        saveLiveRoomClick(){
            liveroomp.saveLiveRoomClick({liveRoomId:this.id})
        },
    },
    beforeDestroy() {
        // this.eventBus.$off(this.$oucy.msgKeys.IM_MESSAGE);

    },

}
</script>
<style scoped lang="less">
.head {
    width: 28px;
    height: 28px;
    border-radius: 28px;
}

.name {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 16px;
    color: #AAAFB7;
    margin-left: 9px;
}

.media {
    max-width: 200px;
    max-height: 200px;
}

.messageBoxs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-left: -20px;
    margin-right: -15px;
}

.messageBox {

    /*padding-bottom: 15px;*/
    .box {
        padding: 15px 20px 15px 20px;

        .rightMore {
            display: none;
        }

        &:hover {
            background: #f1f1f1;

            .rightMore {

                display: inline-block;
            }
        }
    }

    .text {
        display: inline-block;
        max-width: 80%;
        margin-top: 8pxx;
        margin-left: 40px;
        padding: 5px;
        text-align: left;
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 1.5;
        color: #555C68;
        background: #F8F8F8;
        border-radius: 8px;
        position: relative;
        word-wrap: break-word;
        word-break: break-all;
        &.emoji_icon {
            width: 48px;
            // height: auto;
        }
    }

    .triangular {
        position: absolute;
        top: -15px;
        left: 10px;
        display: inline-block;
        width: 0;
        height: 0;
        border-bottom: 10px solid #F8F8F8;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
    }

    .message_createtime {
        text-align: cursor;
        margin: 20px 0;

        span {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 16px;
            color: #AAAFB7;
            background: #F8F8F8;
            opacity: 1;
            border-radius: 16px;
            padding: 4px 16px;
        }
        strong{
            color: #2090FF;
        }
    }
}

.live_identity {
    display: inline-block;
    font-size: 12px;
    padding: 0px 4px;
    background: #82C46F;
    border-radius: 2px;
    color: #fff;
    margin-left: 8px;

    &.live_identity_1 {
        background: #E96666;
    }
}
.reply{
    strong{
        color: #2090FF;
    }
}
</style>