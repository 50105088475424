<!-- 直播播放 -->
<template>
    <div class="livePlay">
        <div id="player-con"></div>
<!--         <video v-if="LiveStream" style="width:100%;height:auto;" id="roomVideo" class="video-js vjs-default-skin vjs-big-play-centered" x-webkit-airplay="allow" :poster="$oucy.ossUrl+liveRoom.roomIcon" webkit-playsinline playsinline x5-video-player-type="h5" x5-video-player-fullscreen="true" autoplay preload="auto" controls>
            <source :src="LiveStream" type="application/x-mpegURL">
        </video> -->
        <div class="imgBox" >
            <img v-if="liveRoom.roomIcon" :src="$oucy.ossUrl + liveRoom.roomIcon" alt="" class="roomIcon">
            <el-button type="primary" class="btn" @click="settingLiveAction" v-if="hasManage">开始直播</el-button>
        </div>
        <div class="pause" v-if="liveRoom.roomStatus==1"><img :src="$oucy.ossUrl + liveRoom.pauseImg" alt="" class="pauseImg" v-if="liveRoom.pauseImg"></div>
    </div>
</template>
<script>
import { liveroom } from '@/service/index.js'
export default {
    name: "aliplayer",
    props: {
        liveRoom: {
            type: Object,
            default: function() {
                return {}
            }
        },
        hasManage: {
            type: Boolean,
            default: false
        },

    },
    data() {
        return {
            hasPlay: false,
            myPlayer: null,
            LiveStream: null,
            id: null,
            player:null,

        }
    },
    mounted() {
        this.id = this.$route.query.id


    },
    methods: {
        getLiveStream(id) {
            liveroom.getLiveStream({ id: id || this.liveRoom.id }).then(res => {
                // 有数据
                if (res) {
                    this.LiveStream = res.rtmp
                   this.player = new Aliplayer({
                      id: "player-con",
                      source: res.m3u8,
                      // source: "//player.alicdn.com/video/editor.mp4",
                      width: "100%",
                      height: "500px",
                      cover: 'https://img.alicdn.com/tps/TB1EXIhOFXXXXcIaXXXXXXXXXXX-760-340.jpg',
                      /* To set an album art, you must set 'autoplay' and 'preload' to 'false' */
                      autoplay: !false,
                      preload: false,
                      isLive: false
                    }, function (player) {
                      console.log("The player is created");
                    });
                }

            })
        },
    },
    beforeDestroy() {
    }
}
</script>
<style scoped lang="less">
.roomIcon,
video {
    width: 100%;
    border-radius: 8px;
}

.imgBox {
    position: relative;
}

.btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.video-js {
    width: 100%;

    .vjs-tech {

        position: relative;
    }
}

.livePlay {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    font-size: 0;
}

.pause {
    position: absolute;
    /*background: #f00;*/
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .pauseImg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 100%;
        max-width: 100%;

    }
}
</style>